import React from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import {Link} from 'react-scroll'
import { useState } from 'react'
export default function Header() {
  const mobile = window.innerWidth <= 560 ? true : false ;
  const [menuOpened,setMenuOpened] = useState(false);
  return (
    <div className='header'>
      <img src={Logo} alt="" className='logo' />
      {menuOpened===false && mobile===true ? (
         <div style={{
          backgroundColor:'var(--appColor)',
          padding:'0.5rem',
          borderRadius:'5px',
          marginTop:'-1rem',
          marginRight:'-1.5rem'
         }}
         onClick={()=>setMenuOpened(true)}
         >
          <div className='burger-menu'>
            <div className="burger-class"></div>
            <div className="burger-class"></div>
            <div className="burger-class"></div>
          </div>
          {/* <img src={Bars} alt="" style={{width:'1.5rem',height:'1.5rem' ,marginTop:'0rem'}} /> */}
         </div>
      ):
      (
        <ul className='header-menu'>
        <li>
          <Link
           onClick={()=>setMenuOpened(false)}
          smooth={true}
          to='hero'
          spy={true}
          activeClass='active'
          >Home</Link>
        </li>
        <li>
          <Link
           onClick={()=>setMenuOpened(false)}
          smooth={true}
          to='programs'
          spy={true}>Programs</Link>
        </li>
        <li>
          <Link
           onClick={()=>setMenuOpened(false)}
          smooth={true}
          to='reasons'
          spy={true}>Why us</Link>
        </li>
        <li>
          <Link
           onClick={()=>setMenuOpened(false)}
          smooth={true}
          to='plans'
          spy={true}>Plans</Link>
        </li>
        <li>
          <Link
           onClick={()=>setMenuOpened(false)}
          smooth={true}
          to='testimonials'
          spy={true}
          >Testimonials</Link>
        </li>
      </ul>
      )
      }

      
    </div>
  )
}
