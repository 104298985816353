import React from 'react'
import './Join.css'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
export default function Join() {
  const form = useRef()

  const handleEmails = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_n9zeno8', 'template_54wzwpr', form.current, 'wbnmFu01bvf1Jkr5Z')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='Join' id='join-us'>
      <div className="left-j">
        <hr />
        <div>
            <span className='stroke-text'>READY TO </span>
            <span>LEVEL UP</span>
        </div>
        <div>
            <span>YOUR BODY </span>
            <span className='stroke-text'>WITH US?</span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} action="" className="email-container" onSubmit={handleEmails}>
            <input type="email" name='user_email' placeholder='Enter your Email address'/>
            <button className='btn btn-j'>Join Now</button>
        </form>
      </div>
    </div>
  )
}
