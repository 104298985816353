import React from 'react'
import './Footer.css'
import gitHub from '../../assets/github.png'
import instagram from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'

export default function Footer() {
  return (
   <>
    <div className='Footer-container'>
      <hr />
      <div className="footer">
      <div className="social-links"><img src={gitHub} alt="" /><img src={linkedin} alt="" /><img src={instagram} alt="" /></div>
      </div>
    </div>
   </>
  )
}
