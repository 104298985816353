import React from 'react'
import '../Programs/Program.css'
import { programsData } from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'

export default function Programs() {
  return (
    <div className="Programs" id='programs'>
        <div className="programs-header ">
            <span className='stroke-text'>Explore Our</span>
            <span className='stroke-text'>Programs</span>
            <span className='stroke-text'>to shape you</span>
        </div>

        <div className="program-categories">
            {programsData.map((program)=>(
                <div className="category">
                    {program.image}
                    <span>{program.heading}</span>
                    <span>{program.details}</span>
                    <div className="join-now">
                        <span><pre>Join Now</pre></span><img src={RightArrow} alt="" /></div>
                </div>
            ))}
        </div>
    </div>
  )
}
