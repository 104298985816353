import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import Heart from '../../assets/heart.png'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import { motion } from 'framer-motion'
import NumberCounter from 'number-counter'
export default function Hero() {

  const transition = {type:'spring', duration: 3}
  return (
    <div className='hero' id='hero'>
      <div className="left-h">
        <Header/>
        <div className="the-best-ad">
            <motion.div
             initial={{left:"260px"}}
             whileInView={{left:"8px"}}
             transition={{...transition,type:"tween"}}>
            </motion.div>
            <span>the best Fitness Center in this town</span>
        </div>
        <div className="hero-text">
          <div>
            <span className='stroke-text'>Shape </span>
            <span>Your </span>
          </div>
          <div>
            <span>Ideal  Body</span>
          </div>
          <div>
            <span>
            Here We will help you to build you Ideal body come and Join Us and Live up your life fullest with us
              </span> 
          </div>
        </div>
        <div className="figures">
          <div>
            <span>
<NumberCounter end={140}  start={89} delay='4' preFix="+" />       
     </span>
            <span>expert coachs</span> 
          </div>
          <div>
            <span><NumberCounter end={978}  start={900} delay='4' preFix="+" /></span>
            <span>members joined</span> 
          </div>
          <div>
            <span><NumberCounter end={50}  start={0} delay='4' preFix="+" /></span>
            <span>fitness programs</span> 
          </div>
        </div>
        <div className="hero-buttons">
          <buttons className="btn">Get Started</buttons>
          <buttons className="btn">Learn More</buttons>
        </div>
      </div>
      <div className="right-h">
        <button className='btn'>
          Join Now
        </button>


        <motion.div className="heart-rate"
        initial={{right:"1rem"}}
        whileInView={{right:"4rem"}}
        transition={{...transition, type:'tween'}}>
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>

        <img src={hero_image} alt="" className='hero-image' />
        <motion.img 
        initial= {{right:"11rem"}}
        whileInView={{right:"25rem"}}
        transition={{...transition, type:'tween'}}
        src={hero_image_back} alt="" className='hero-image-back' />
      </div>
    </div>
  )
}
